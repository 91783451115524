/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"

const Darkmode: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.14806 2.26629C9.41557 2.5338 9.48945 2.93901 9.33356 3.28372C8.84577 4.36233 8.57377 5.56025 8.57377 6.82414C8.57377 11.5749 12.4251 15.4262 17.1759 15.4262C18.4398 15.4262 19.6377 15.1542 20.7163 14.6664C21.061 14.5105 21.4662 14.5844 21.7337 14.8519C22.0012 15.1194 22.0751 15.5247 21.9192 15.8694C20.2851 19.4828 16.6476 22 12.4203 22C6.66531 22 2 17.3347 2 11.5797C2 7.35239 4.51721 3.71493 8.13064 2.08079C8.47534 1.92491 8.88056 1.99879 9.14806 2.26629ZM6.92123 4.96436C5.02443 6.54285 3.81818 8.92099 3.81818 11.5797C3.81818 16.3305 7.66947 20.1818 12.4203 20.1818C15.079 20.1818 17.4571 18.9756 19.0356 17.0788C18.4319 17.1876 17.8103 17.2444 17.1759 17.2444C11.4209 17.2444 6.75559 12.5791 6.75559 6.82414C6.75559 6.18972 6.81239 5.56814 6.92123 4.96436Z'
                fill='currentColor'
            />
        </svg>
    )
}
export default Darkmode
